import { keyframes, css } from '@emotion/core'

const container = css`
  position: relative;
  width: 80%;
  max-width: 60rem;
  margin: 0 auto;
`

const dev = css`
  border: 1px solid tomato;
`

const baseClasses = {
  container,
  dev,
}

const keyframesFadeIn = keyframes`
  from { opacity: 0; }
  to:  { opacity: 1; }
`

const getOpacity = (status, hide) => {
  let opacity = 1
  switch (status) {
    case 'exiting':
    case 'exited':
      opacity = 0
      break
    case 'entering':
    case 'entered':
      opacity = 1
      break
  }

  return hide ? 0 : opacity
}

export { getOpacity, keyframesFadeIn, baseClasses }
