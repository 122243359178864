import React from 'react'
import { withFormsy } from 'formsy-react'
import { css } from '@emotion/core'

const inputStateColor = ({ showMessage, valid }) => {
  if (!showMessage) return '255, 255, 255'
  if (valid) return '38, 230, 16'
  return '245, 219, 35'
}

const withGroup = InputElement =>
  class Group extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isFocused: false,
        showMessage: false,
      }

      this.ref = React.createRef()
      this.inputId = `${this.props.name}-${this.props.type}`
      this.focusHandler = this.focusHandler.bind(this)
      this.changeValue = this.changeValue.bind(this)

      this.timer = null
    }

    componentDidMount() {
      const $input = this.ref.current
      $input.addEventListener('focus', this.focusHandler)
      $input.addEventListener('blur', this.focusHandler)
    }

    componentWillUnmount() {
      const $input = this.ref.current
      $input.removeEventListener('focus', this.focusHandler)
      $input.removeEventListener('blur', this.focusHandler)
      clearTimeout(this.timer)
    }

    focusHandler() {
      this.setState(state => ({
        isFocused: !state.isFocused,
      }))
    }

    changeValue(event) {
      this.props.setValue(event.currentTarget.value)
      if (this.state.showMessage) this.setState({ showMessage: false })
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.setState({ showMessage: true })
      }, 1000)
    }

    render() {
      const { isFocused, showMessage } = this.state
      const {
        label,
        type,
        name,
        value,
        isValid,
        getErrorMessage,
        showRequired,
      } = this.props
      const message = showRequired ? 'required' : getErrorMessage
      const valid = isValid
      const color = inputStateColor({ valid, showMessage })

      return (
        <div
          css={css`
            position: relative;
            margin-bottom: 1.5rem;
          `}
        >
          <label
            htmlFor={this.inputId}
            css={css`
              position: absolute;
              height: auto;
              top: 0;
              left: 0;
              font-size: 0.6rem;
              font-weight: 600;
              text-transform: uppercase;
              transform: translateY(-${isFocused ? 100 : 80}%);
              opacity: ${isFocused ? 1 : 0};
              transition: all 0.3s ease;
            `}
          >
            {label}
          </label>
          <InputElement
            ref={this.ref}
            id={this.inputId}
            type={type}
            name={name}
            onChange={this.changeValue}
            value={value || ''}
            color={color}
            placeholder={isFocused ? '' : label}
          />
          <p
            css={css`
              font-size: 0.7rem;
              color: rgb(${color});
            `}
          >
            {showMessage && message}
          </p>
        </div>
      )
    }
  }

const inputStyle = css`
  border: none;
  position: relative;
  width: 100%;
  padding: 0.5em 0.75em;
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease;

  &:focus {
    outline: 0;
    background: rgba(255, 255, 255, 0.08);
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  }
`

const Input = React.forwardRef(function ForwaredInput(props, ref) {
  const { color } = props
  return (
    <input
      ref={ref}
      css={[
        inputStyle,
        css`
          border-bottom: 1px solid rgba(${color}, 0.4);
          &:focus {
            border-bottom: 1px solid rgba(${color}, 0.8);
          }
        `,
      ]}
      {...props}
    />
  )
})

const Textarea = React.forwardRef(function ForwardedTextarea(props, ref) {
  const { color } = props
  return (
    <textarea
      ref={ref}
      css={[
        inputStyle,
        css`
          resize: vertical;
          height: 8rem;
          border-bottom: 1px solid rgba(${color}, 0.4);
          &:focus {
            border-bottom: 1px solid rgba(${color}, 0.8);
          }
        `,
      ]}
      {...props}
    />
  )
})

const InputGroup = withFormsy(withGroup(Input))
const TextareaGroup = withFormsy(withGroup(Textarea))

const defaultProps = {
  type: 'text',
  label: 'default label',
  name: 'default',
}

InputGroup.defaultProps = defaultProps
TextareaGroup.defaultProps = defaultProps

export { InputGroup, TextareaGroup, inputStyle }
