import React from 'react'
import { css } from '@emotion/core'

class Warning extends React.Component {
  state = {
    isOpen: false,
    content: `원더보이즈필름(이하 “회사”라 함)은 이용자들의 개인정보보호를 매우 중요시하며, "정보통신망 이용촉진 및 정보보호에 관한 법률”을 준수하고 있습니다.
    회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
    회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.

    윤호유통의 개인정보 취급방침은 다음과 같은 내용을 담고 있습니다.
    `,
  }

  clickHandler = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  render() {
    const { isOpen, content } = this.state
    const truncated = content.substring(0, 16) + '...'
    return (
      <div
        css={css`
          border: 1px solid rgba(255, 255, 255, 0.2);
          margin-bottom: 1.5rem;
          padding: 0.75rem 1rem;
          color: rgba(255, 255, 255, ${isOpen ? '1' : '0.6'});
          transition: color 0.3s ease;

          h6 {
            margin: 0;
            font-size: 0.8rem;
            margin-bottom: 0.5rem;
          }

          p {
            display: inline-block;
            margin: 0;
            font-size: 0.75rem;
          }

          button {
            display: inline;
            border: none;
            background: none;
            color: #fff;
            font-size: 0.75rem;
          }
        `}
      >
        <h6>개인정보취급방침</h6>
        <p>{isOpen ? content : truncated}</p>
        <button onClick={this.clickHandler}>{isOpen ? 'less' : 'more'}</button>
      </div>
    )
  }
}

export default Warning
