import React from 'react'
import Warning from '../contact/warning'
import Form from '../contact/form'

const Contact = () => (
  <>
    <Warning />
    <Form />
  </>
)

export default Contact
