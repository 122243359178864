import React from 'react'
import styled from '@emotion/styled'

import SEO from '../components/seo'
import Layout from '../components/layout'

import { Nav } from '../components/nav'

import { baseClasses } from '../components/styles'
import { Header } from '../components/typography'
import Contact from '../components/contact/contact'
import Location from '../components/contact/location'
import { fadeIn, getPageName, mq } from '../components/utils'

const ContactLayout = styled.div`
  ${baseClasses.container}
  margin-top: 7rem;
  display: flex;

  & .location-container {
    position: relative;
    margin-right: 2rem;
    flex: 2 1;
    animation: ${fadeIn} 0.3s ease-out;
    animation-fill-mode: both;
  }
  & .contact-container {
    position: relative;
    flex: 1 1;
    animation: ${fadeIn} 0.3s ease-out;
    animation-fill-mode: both;
    animation-delay: 0.15s;
  }

  ${mq[0]} {
    flex-wrap: wrap;
    margin-top: 0;
    width: 85%;
    & .location-container,
    & .contact-container {
      margin: 0;
      flex: 1 1 100%;
    }

    & .hidden {
      display: none;
    }

    & .contact-header {
      display: none;
    }
  }
`

const TabContainer = styled.div`
  margin-top: 5rem;
  width: 100%;
  display: none;
  padding: 1rem 2rem;

  ${mq[0]} {
    display: block;
  }
`

const Tab = styled.button`
  border: none;
  background: none;
  font-size: 1.25rem;
  color: ${({ isActive }) => (isActive ? '#fff' : 'rgba(255, 255, 255, 0.6)')};
  width: 50%;
  padding: 0.5rem 0;
  margin: 0;
  &:last-of-type {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
`

class Menu extends React.Component {
  state = {
    currentTab: 'location',
  }
  toggleTab = (tab = 'location') => {
    this.setState({ currentTab: tab })
  }
  render() {
    const { currentTab } = this.state
    return (
      <>
        <TabContainer>
          <Tab
            isActive={currentTab === 'location'}
            onClick={() => this.toggleTab('location')}
          >
            Location
          </Tab>
          <Tab
            isActive={currentTab === 'contact'}
            onClick={() => this.toggleTab('contact')}
          >
            Contact
          </Tab>
        </TabContainer>
        <ContactLayout>
          <div
            className={`location-container ${currentTab !== 'location' &&
              'hidden'}`}
          >
            <Header className="contact-header">Location</Header>
            <Location />
          </div>
          <div
            className={`contact-container ${currentTab !== 'contact' &&
              'hidden'}`}
          >
            <Header className="contact-header">Contact</Header>
            <Contact />
          </div>
        </ContactLayout>
      </>
    )
  }
}

const ContactPage = ({ data }) => {
  return (
    <>
      <Nav />
      <SEO name="Contact" />
      <Menu />
    </>
  )
}

export default ContactPage
