import React from 'react'
import { css } from '@emotion/core'
import FadeInImage from '../fadeInImage'

const Map = () => (
  <a
    href="https://goo.gl/maps/amqvXSNGGbP2"
    target="_blank"
    rel="noopener noreferrer"
    css={css`
      position: relative;
      display: block;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0;
        transition: opacity 0.3s ease;
      }
      &:after {
        content: 'View Map →';
        position: absolute;
        top: 2em;
        right: 2em;
        color: #fff;
        opacity: 0;
        transform: translateY(-1rem);
        transition: all 0.3s ease;
      }

      &:hover:before {
        opacity: 0.2;
      }
      &:hover:after {
        opacity: 1;
        transform: translateY(0);
      }
    `}
  >
    <FadeInImage
      css={css`
        height: auto;
      `}
      src="/map.png"
      alt="Wonderboys Korea's HQ location on the map"
      width="600"
      height="400"
    />
  </a>
)

export default Map
