import React from 'react'
import Formsy from 'formsy-react'
import { navigate } from 'gatsby'
import { TextareaGroup, InputGroup } from '../input'
import { Button, WhiteButton } from '../button'
import URLSearchParams from '@ungap/url-search-params'
import Modal from '../modal'
import { mq } from '../utils'
import styled from '@emotion/styled'

const ModalLayout = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  & > button:first-of-type {
    margin-right: 0.5rem;
  }

  ${mq[0]} {
    & > button {
      margin-bottom: 1rem;
    }

    & > button:first-of-type {
      margin-right: 0;
    }
  }
`

const SuccessModal = ({ close }) => (
  <ModalLayout>
    <h1>Form sent</h1>
    <p>Thank you for reaching out.</p>
    <Button
      size="1"
      onClick={() => {
        navigate('/')
      }}
    >
      Home Page
    </Button>
    <Button size="1" onClick={close}>
      Close
    </Button>
  </ModalLayout>
)

const FailedModal = ({ close }) => (
  <ModalLayout>
    <h1>Failed</h1>
    <p>Please try again</p>
    <Button size="1" onClick={close}>
      Close
    </Button>
  </ModalLayout>
)

class Form extends React.Component {
  state = {
    canSubmit: false,
    isSending: false,
    isCompleted: false,
    error: false,
  }

  disableButton = () => {
    this.setState({ canSubmit: false })
  }

  enableButton = () => {
    this.setState({ canSubmit: true })
  }

  submit = async model => {
    this.setState({
      canSubmit: false,
      isSending: true,
    })
    await fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        ...model,
        'form-name': 'contact',
      }).toString(),
    }).catch(error =>
      this.setState({
        error,
      })
    )

    setTimeout(() => {
      this.setState({
        isSending: false,
        canSubmit: true,
        isCompleted: true,
      })
    }, 2000)
  }

  close = () => {
    this.setState({
      isCompleted: false,
      error: false,
    })
  }

  render() {
    const { canSubmit, isSending, isCompleted, error } = this.state
    return (
      <Formsy
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <input hidden name="bot-field" />
        <InputGroup
          name="company"
          validations={{ isExisty: true }}
          validationErrors={{ isExisty: 'Please fill out this form' }}
          label={`회사명 & 성함`}
          type="text"
          required
        />
        <InputGroup
          required
          name="email"
          validations={{ isEmail: true }}
          validationErrors={{ isEmail: 'You have to type valid email' }}
          label={`Email`}
          type="email"
        />
        <InputGroup
          required
          name="phone"
          validations={{ isNumeric: true }}
          validationErrors={{ isNumeric: 'Please enter a valid phone number' }}
          label={`Phone`}
          type="tel"
        />
        <TextareaGroup
          required
          name="content"
          validations={{ isExisty: true }}
          validationErrors={{ isExisty: 'Please fill out this form' }}
          label={`Content`}
          type="text"
        />
        <WhiteButton fluid size="1" type="submit" disabled={!canSubmit}>
          {isSending ? 'Sending...' : 'Send'}
        </WhiteButton>
        <Modal isOpen={isCompleted}>
          {error ? (
            <FailedModal close={this.close} />
          ) : (
            <SuccessModal close={this.close} />
          )}
        </Modal>
      </Formsy>
    )
  }
}

export default Form
