import styled from '@emotion/styled'

const defaultProps = {
  size: 2,
  outline: false,
  fluid: false,
}

const Button = styled('button', {
  shouldForwardProp: prop => Object.keys(defaultProps).indexOf(prop) < 0,
})`
  color: #fff;
  font-weight: 600;
  padding: 0.75em 1.5em;
  box-sizing: border-box;
  text-decoration: none;
  transition: all 0.3s ease;
  opacity: 1;
  width: ${props => (props.fluid ? '100%' : 'auto')};
  font-size: ${props => props.size}rem;
  border: none;
  background: rgba(255, 255, 255, 0.12);

  &:disabled {
    opacity: 0.2;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  &:focus {
    outline: 0;
  }
`

const WhiteButton = styled(Button)`
  color: #000;
  background: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1rem;

  &:disabled {
    color: rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.12);
    opacity: 1;
  }
`

const OutlineButton = styled(Button)`
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
  &:hover {
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.6);
  }

  &:focus {
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.8);
  }
`

Button.defaultProps = defaultProps
OutlineButton.defaultProps = defaultProps

export { Button, OutlineButton, WhiteButton }
